import React from "react";
import MainLayout from "@/layouts/MainLayout";
import { COLORS, TYPOGRAPHY } from "@/constants";
import { Seo } from "@/components/Seo";
import { Container } from "@/components/Container";
import styled from "styled-components";
import { graphql } from "gatsby";

const Cookie = () => {
  const seoData = {
    metaDescription: "Правила Использования «cookie»",
    metaTitle: "Правила Использования «cookie»",
    keywords: "cookie",
  };

  return (
    <MainLayout>
      <Seo data={seoData} />
      <Root>
        <StyledContainer>
          <Title>Правила Использования «cookie»</Title>
          <p>
            В настоящем документе Пользователям сообщается о технологиях, с
            помощью которых Приложение достигает описанных ниже целей. Указанные
            технологии позволяют Владельцу получать доступ к информации и
            сохранять ее (например, использование Файлов «cookie») или
            использовать ресурсы (например, выполнять код скриптов) на
            устройстве Пользователя во время взаимодействия с Приложением.
          </p>

          <p>
            Для упрощения все указанные технологии определяются в настоящем
            документе как «Трекеры», кроме тех случаев, когда необходимо
            установить их различие. Например, хотя Файлы «cookie» могут
            использоваться как в веб-браузерах, так и в мобильных браузерах,
            было бы неточно говорить о Файлах «cookie» в контексте мобильных
            приложений, поскольку они являются трекерами. По этой причине в
            настоящем документе термин Файлы «cookie» используется только в тех
            случаях, когда он употребляется в отношении данного конкретного типа
            Трекеров.
          </p>

          <p>
            Некоторые из целей, для достижения которых используются Трекеры,
            могут предусматривать обязательное получение согласия Пользователя.
            Любое предоставленное согласие может быть свободно отозвано в любое
            время в соответствии с инструкциями, которые изложены в настоящем
            документе.
          </p>

          <p>
            Приложение использует Трекеры, которыми управляет непосредственно
            Владелец (так называемые «собственные» Трекеры), и Трекеры, которые
            задействуют сервисы, предоставляемые третьими сторонами (так
            называемые «сторонние» Трекеры).
          </p>

          <p>
            Если в настоящем документе не указано иное, сторонние поставщики
            могут получить доступ к Трекерам, находящимся под их управлением.
          </p>

          <p>
            Срок действия и истечение срока действия Файлов «cookie» и иных
            аналогичных Трекеров могут варьироваться в зависимости от срока
            использования, установленного Владельцем или соответствующим
            поставщиком. Срок действия некоторых из них истекает по завершении
            сеанса просмотра страниц Пользователем.
          </p>

          <p>
            Помимо сведений, приведённых в описаниях каждой из нижеперечисленных
            категорий, Пользователи могут ознакомиться с более точной и
            актуальной информацией о сроках действия, а также любой иной
            полезной информацией — в частности, о наличии других Трекеров, —
            воспользовавшись ссылками на правила обеспечения конфиденциальности
            персональных данных, установленные соответствующими сторонними
            поставщиками, или обратившись к Владельцу.
          </p>

          <Heading3>
            Действия, которые в обязательном порядке необходимы для того, чтобы
            Приложение работало и могло предоставлять Услугу
          </Heading3>

          <p>
            Приложение использует так называемые «технические» Файлы «cookie» и
            другие аналогичные Трекеры для выполнения действий,которые в
            обязательном порядке необходимы для функционирования или
            предоставления Услуги.
          </p>

          <Heading3>
            Другие виды деятельности, связанные с использованием Трекеров
          </Heading3>

          <p>
            <b>Количественная оценка</b>
          </p>

          <p>
            Приложение использует Трекеры для измерения трафика и анализа
            поведения Пользователей с целью улучшения Услуги.
          </p>

          <p>
            <b>Аналитика</b>
          </p>

          <p>
            Услуги, описываемые в этом разделе, дают возможность Владельцу
            контролировать и анализировать веб-трафик и могут использоваться для
            фиксирования действий Пользователя.
          </p>

          <p className="red">
            <b>Appsflyer (AppsFlyer Ltd)</b>
          </p>

          <p className="red">
            Appsflyer — аналитический сервис, предоставляемый компанией
            AppsFlyer Ltd.
          </p>
          <p className="red">
            Обрабатываемые персональные данные: Данные об использовании сетевых
            ресурсов и Трекер.
          </p>
          <p className="red">
            Место обработки: Израиль —{" "}
            <a
              href="https://www.appsflyer.com/legal/privacy-policy/"
              target="_blank"
              className="link"
            >
              Правила обеспечения конфиденциальности персональных данных – Отказ
              от участия.
            </a>
          </p>

          <Heading3>
            Управление предпочтениями, предоставление или отзыв согласия
          </Heading3>

          <p>
            Существуют различные способы управления предпочтениями, связанными с
            Трекерами, предоставлением и отзывом согласия в соответствующих
            случаях:
          </p>

          <p>
            Пользователи могут управлять предпочтениями, связанными с Трекерами,
            непосредственно в настройках собственного устройства, в частности,
            предотвращая использование или сохранение Трекеров.
          </p>

          <p>
            Кроме того, когда для использования Трекеров требуется получение
            согласия, Пользователи могут предоставить или отозвать такое
            согласие, настроив соответствующим образом свои предпочтения в
            уведомлении о Файлах «cookie» или обновив соответствующим образом
            предпочтения с помощью специального мини-приложения для управления
            предоставленными согласиями, если таковое имеется.
          </p>

          <p>
            Также можно с помощью соответствующих функций браузера или
            устройства удалить ранее сохраненные Трекеры, в том числе
            используемые для запоминания первоначального согласия Пользователя.
          </p>

          <p>
            Другие Трекеры в локальной памяти браузера можно обнулить, удалив
            историю просмотров.
          </p>

          <p>
            Что касается любых сторонних Трекеров, Пользователи могут управлять
            своими предпочтениями и отозвать свое согласие с помощью
            соответствующей ссылки для отзыва предоставленного согласия (если
            таковая имеется), или с помощью средств, указанных в правилах
            обеспечения конфиденциальности персональных данных, используемых
            соответствующей третьей стороной, или связавшись третьей стороной.
          </p>

          <p>
            <b>Доступ к настройкам Трекера</b>
          </p>

          <p>
            К примеру, Пользователи могут найти информацию о порядке управления
            Файлами «cookie» в наиболее популярных браузерах о следующим
            адресам:
          </p>

          <ul>
            <li>
              <a
                className="link"
                target="_blank"
                href="https://support.google.com/chrome/answer/95647?hl=ru&p=cpn_cookies"
              >
                Google Chrome
              </a>
            </li>
            <li>
              <a
                className="link"
                target="_blank"
                href="https://support.mozilla.org/ru/kb/uluchshennaya-zashita-ot-otslezhivaniya-v-firefox-"
              >
                Mozilla Firefox
              </a>
            </li>
            <li>
              <a
                className="link"
                target="_blank"
                href="https://support.apple.com/ru-ru/guide/safari/sfri11471/mac"
              >
                Apple Safari
              </a>
            </li>
            <li>
              <a
                className="link"
                target="_blank"
                href="https://support.microsoft.com/ru-ru/windows/%D1%83%D0%B4%D0%B0%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-%D1%84%D0%B0%D0%B9%D0%BB%D0%BE%D0%B2-cookie-%D0%B8-%D1%83%D0%BF%D1%80%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-%D0%B8%D0%BC%D0%B8-168dab11-0753-043d-7c16-ede5947fc64d"
              >
                Microsoft Internet Explorer
              </a>
            </li>
            <li>
              <a
                className="link"
                target="_blank"
                href="https://support.microsoft.com/ru-ru/microsoft-edge/%D1%83%D0%B4%D0%B0%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-%D1%84%D0%B0%D0%B9%D0%BB%D0%BE%D0%B2-cookie-%D0%B2-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09"
              >
                Microsoft Edge
              </a>
            </li>
            <li>
              <a
                className="link"
                target="_blank"
                href="https://support.brave.com/hc/en-us/articles/360022806212-How-do-I-use-Shields-while-browsing"
              >
                Brave
              </a>
            </li>
            <li>
              <a
                className="link"
                target="_blank"
                href="https://help.opera.com/ru/latest/web-preferences/#cookies"
              >
                Opera
              </a>
            </li>
          </ul>

          <p>
            Пользователи могут также управлять определенными категориями
            Трекеров, используемых в мобильных приложениях, отказываясь от
            соответствующих настроек устройств, таких как настройки рекламы для
            мобильных устройств или настройки отслеживания действий Пользователя
            в целом (Пользователи могут открыть экран настроек устройства и
            найти соответствующую опцию).
          </p>

          <Heading3>Владелец и Контролёр данных</Heading3>

          <p className="red">
            Penguin LLC -Room 10, Floor 2, Section 1, Building 31, Zorge street,
            125252 Moscow, Russia
          </p>

          <p>
            <b>Контактный адрес электронной почты Владельца: </b>{" "}
            <span className="red">penguinappcustomerservice@gmail.com</span>
          </p>

          <p>
            В связи с тем, что Владелец не имеет технической возможности
            контролировать использованиесторонних Трекеров в полном объеме при
            посредстве услуг, которые предлагает Приложение, любые конкретные
            указания на сторонние Трекеры следует рассматривать как
            ориентировочные. Для получения полной информации Пользователям
            настоятельно рекомендуется ознакомиться с правилами обеспечения
            конфиденциальности персональных данных применительно к
            соответствующим сторонним сервисам, перечисленным в настоящем
            документе.
          </p>

          <p>
            Учитывая объективную сложность, связанную с технологиями
            отслеживания, Пользователям рекомендуется связаться с Владельцем,
            если они хотят получить любую дополнительную информацию о том, как
            Приложение использует данные технологии.
          </p>

          <Heading3>Определения терминов и ссылки на правовые нормы</Heading3>

          <p className="m-16">
            <b>Персональные данные (или Данные)</b>
          </p>

          <p>
            Любая информация, которая прямо, косвенно или в связи с другой
            информацией — в том числе персональным идентификационным номером —
            обеспечивает возможность выявления или узнаваемость физического
            лица.
          </p>

          <p className="m-16">
            <b>Данные об использовании (сетевых ресурсов)</b>
          </p>

          <p>
            Информация, которую автоматически собирают Приложение или сторонние
            сервисы, которые использует Приложение, в частности: IP-адреса или
            доменные имена компьютеров Пользователей, которые используют
            Приложение, URI-адреса (универсальные коды ресурсов), время
            обращения с запросом, способ обращения с запросом к серверу, размер
            файла, полученного в ответ, числовой код, указывающий на статус
            ответа сервера (удачный исход, ошибка и т.д.), страна происхождения,
            характеристики веб-обозревателя (браузера) и операционной системы,
            используемых Пользователем, различные сведения о времени каждого
            посещения (например, время, проведенное на каждой странице
            Приложения), и сведения о перемещениях внутри Приложения с отдельным
            указанием очередности посещения страниц, а также другие параметры
            операционной системы устройства и (или) вычислительнойи
            информационной среде Пользователя.
          </p>

          <p>
            <b>Пользователь</b>
          </p>
          <p>
            Отдельное лицо, использующее Приложение, которое, если не указано
            иное, совпадает с Субъектом данных.
          </p>

          <p>
            <b>Субъект данных</b>
          </p>
          <p>Физическое лицо, к которому относятся Персональные данные.</p>

          <p>
            <b>Обработчик данных (или Администратор данных)</b>
          </p>
          <p>
            Физическое или юридическое лицо, официальный государственный орган,
            агентство или иной орган, которые обрабатывают персональные данные
            по поручению контролера.
          </p>

          <p>
            <b>Контролер (или Владелец) данных</b>
          </p>
          <p>
            Физическое или юридическое лицо, государственный орган, ведомство
            или иное лицо, которое самостоятельно или совместно с другими лицами
            определяет предназначение и средства обработки Персональных данных,
            включая меры безопасности, касающиеся того, как эксплуатируется и
            используется Приложение. Если не указано иное, Контролером данных
            является Владелец, т. е. лицо, которому принадлежит Приложение.
          </p>

          <p>
            <b>Приложение</b>
          </p>
          <p>
            Средство, с помощью которого осуществляются сбор и обработка
            Персональных данных Пользователя.
          </p>

          <p>
            <b>Услуга (или Сервис)</b>
          </p>
          <p>
            Услуга, которую предоставляет Приложение, описанная в
            соответствующих условиях (если таковые имеются), а также на этом
            сайте или в этом приложении.
          </p>

          <p>
            <b>Европейский союз (или ЕС)</b>
          </p>
          <p>
            Если не указано иное, все встречающиеся в настоящем документе ссылки
            на Европейский союз включают все существующие в настоящее время
            страны-члены Европейского союза и Европейской экономической зоны.
          </p>

          <p>
            <b>Файл «cookie»</b>
          </p>
          <p>
            Файлы «cookie» — это Трекеры, состоящие из маленьких наборов данных,
            хранящихся в браузере Пользователя.
          </p>

          <p>
            <b>Трекер</b>
          </p>
          <p>
            Трекером называется любая технология (например, Файлы «cookie»,
            уникальные идентификаторы, веб-маяки, встроенные скрипты,
            электронные теги и отпечатки пальцев), которая позволяет отслеживать
            действия Пользователей, например, получая доступ к информации на
            устройстве Пользователя и сохранять ее на этом устройстве.
          </p>

          <div>
            <hr />
          </div>

          <p>
            <b>Правовая информация</b>
          </p>
          <p>
            Настоящее заявление о конфиденциальности подготовлено на основе
            положений различных законодательных актов, в том числе ст. 13/14
            Постановления (ЕС) 2016/679 («Общие положения о защите персональных
            данных»).
          </p>

          <p>
            Если в настоящем документе не указано иное, то действие правил
            обеспечения конфиденциальности персональных данных распространяется
            исключительно на Приложение.
          </p>
        </StyledContainer>
      </Root>
    </MainLayout>
  );
};

const Root = styled.section`
  .red {
    color: red;
  }
`;

const StyledContainer = styled(Container)`
  padding-top: 128px;
  padding-bottom: 120px;
  @media (max-width: 768px) {
    padding-top: 64px;
    padding-bottom: 64px;
  }
`;

const Title = styled.h1`
  ${TYPOGRAPHY.title1Bold64};
  text-align: center;
  color: ${COLORS.primaryDarkBlue};
  padding-bottom: 80px;
  @media (max-width: 768px) {
    text-align: left;
    padding-bottom: 16px;
  }
  @media (max-width: 768px) {
    ${TYPOGRAPHY.title3Bold28};
  }
`;

const Heading3 = styled.h3`
  ${TYPOGRAPHY.title3Bold28};
  color: ${COLORS.grayscaleDarkmode};
  padding-top: 80px;
  padding-bottom: 40px;
  @media (max-width: 768px) {
    color: ${COLORS.primaryDarkBlue};
    padding-top: 64px;
    padding-bottom: 16px;
    ${TYPOGRAPHY.bodyReg16M};
  }
`;

export default Cookie;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
